import React from 'react';
import Helmet from 'react-helmet';
import ReactResizeDetector from 'react-resize-detector';

import Description from './Description';
import ProductEntry from './ProductEntry';

import ContactForm from './ContactForm';
import { slug } from '@mangoart/gatsby-ui/helper/Slugger';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

HerstellerDetail.propTypes = {};
HerstellerDetail.defaultProps = {};

function HerstellerDetail({ data }) {
  const { manufacturer, produktGruppen } = data;
  const { name, description, products, image } = manufacturer;

  console.log(manufacturer);

  const filteredProducts = Array.isArray(products)
    ? products.filter((product) => {
        return product != null;
      })
    : [];

  const countProducts = (products) => {
    let validProducts = 0;
    products.forEach((product) => {
      if (product) {
        const { image, name } = product;
        if (image && name) {
          validProducts++;
        }
      }
    });
    return validProducts;
  };

  const determineNumberOfColumns = (width) => {
    if (!width) return 4;
    if (width > 1100) return 4;
    if (width > 850) return 3;
    if (width > 600) return 2;
    return 1;
  };

  const validProducts = countProducts(filteredProducts);

  const renderProductGroup = ({ node }) => (
    <ProductEntry
      product={{
        name: node.name,
        image: node.boximage,
        link: `/hersteller/${slug(manufacturer.name)}/${slug(node.name)}`,
      }}
      key={node.id}
    />
  );

  return (
    <article className="defaultWidth">
      <div>
        <Helmet>
          <title>{name}</title>
          <meta name="description" content={description} />
        </Helmet>
        <GatsbyImage alt={name} image={getImage(manufacturer.image)} />
        <div className="wrap">
          <Description node={manufacturer} />
          <ReactResizeDetector handleWidth>
            {(size) => {
              const width = size ? size.width : 1000;
              const numberOfColumns = determineNumberOfColumns(width);
              const contactFormWidth =
                validProducts % numberOfColumns === 0
                  ? numberOfColumns
                  : numberOfColumns - (filteredProducts.length % numberOfColumns);
              return (
                <div style={{ margin: '0 -4px -8px -4px' }}>
                  {produktGruppen && produktGruppen.length > 0
                    ? produktGruppen.map(renderProductGroup)
                    : filteredProducts
                    ? filteredProducts.map((product) => (
                        <ProductEntry product={product} key={product.name} cols={numberOfColumns} />
                      ))
                    : null}
                  <ContactForm
                    cols={numberOfColumns}
                    width={contactFormWidth}
                    contactPhone={manufacturer.contactPhone}
                  />
                </div>
              );
            }}
          </ReactResizeDetector>
        </div>
      </div>
    </article>
  );
}

export default HerstellerDetail;
