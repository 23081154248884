import React from 'react';
import PropTypes from 'prop-types';

import HerstellerDetail from '../components/HerstellerDetail';
import DefaultLayout from '../layout/DefaultLayout';
import { graphql } from 'gatsby';

HerstellerTemplate.propTypes = {
  pathContext: PropTypes.shape().isRequired,
};

function HerstellerTemplate({ data }) {
  const { item, ProductGruppen } = data;

  return (
    <DefaultLayout>
      <HerstellerDetail data={{ manufacturer: item, produktGruppen: ProductGruppen }} />
    </DefaultLayout>
  );
}

export default HerstellerTemplate;

export const query = graphql`
  query ($id: String) {
    item: cockpitHersteller(id: { eq: $id }) {
      id
      name
      country
      description
      site
      category
      contactPhone
      image {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 1600)
        }
      }
      products {
        name
        image {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 420, aspectRatio: 1.6)
          }
        }
      }
      logopng {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 400)
        }
      }
      logojpg {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 400)
        }
      }
    }

    ProductGruppen: allCockpitProduktgruppe {
      edges {
        node {
          id
          name
          boximage {
            publicURL
            processed: childImageSharp {
              gatsbyImageData(width: 640)
            }
          }
          manufacturer {
            id
            name
          }
        }
      }
    }
  }
`;
