import React from 'react';
import { Link, navigate } from 'gatsby';
import classNames from 'classnames';

import * as styles from './ProductEntry.module.css';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default function ProductEntry({ product, cols }) {
  if (!product) {
    return null;
  }
  const { id, name, image, link } = product;

  const valid = image && name;

  const navigateToDetailPage = (e) => {
    e.preventDefault();
    navigate(link);
  };

  const classes = classNames(styles.content, styles[`box1Of${cols || 4}`], {
    [styles.hasLink]: link,
  });

  if (valid) {
    return (
      <div className={classes} onClick={navigateToDetailPage} role="presentation" key={id}>
        <div className={styles.top}>
          <GatsbyImage alt={name} image={getImage(image)} />
        </div>
        <div className={styles.actionContainer}>
          {link ? (
            <Link to={link} styleName={styles.action}>
              {name}
            </Link>
          ) : (
            <div className={styles.action}>{name}</div>
          )}
        </div>
      </div>
    );
  }
  return null;
}
