// extracted by mini-css-extract-plugin
export var ContactForm = "ContactForm-module--ContactForm--dzFXF";
export var box = "ContactForm-module--box--G8ZN3";
export var box1Of1 = "ContactForm-module--box1Of1--gEpL3";
export var box1Of2 = "ContactForm-module--box1Of2--8oUK6";
export var box1Of3 = "ContactForm-module--box1Of3--BISw2";
export var box1Of4 = "ContactForm-module--box1Of4--8MJ8i";
export var box2Of2 = "ContactForm-module--box2Of2--nTFuq";
export var box2Of3 = "ContactForm-module--box2Of3--OBLzJ";
export var box2Of4 = "ContactForm-module--box2Of4--xpIs6";
export var box3Of3 = "ContactForm-module--box3Of3--PY+8R";
export var box3Of4 = "ContactForm-module--box3Of4--ov75R";
export var box4Of4 = "ContactForm-module--box4Of4--QYSkh";
export var phoneContact = "ContactForm-module--phoneContact--Tdr8y";